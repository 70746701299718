import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Ensure this file includes the new styles

const Home = () => {
  return (
    <div className="home-container">
      <video autoPlay loop muted className="background-video">
        <source src="https://videos.pexels.com/video-files/4686967/4686967-uhd_2560_1440_24fps.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay">
        <h1 className="main-text">Enhancing Human Connection with AI</h1>
        <Link to="/about" className="stay-updated">STAY UPDATED</Link>
      </div>
    </div>
  );
}

export default Home;