import React, { useState } from 'react';
import './About.css'; // Ensure this CSS file includes the new styles

const About = () => {
  const [email, setEmail] = useState('');

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (email.trim() !== '') {
      // Clear the input field
      setEmail('');
      // Show success message
      alert('Email Successfully Signed Up');
    } else {
      // Optionally handle case where email is empty
      alert('Please enter an email address');
    }
  };

  const handleCopyEmail = () => {
    // Copy email to clipboard
    navigator.clipboard.writeText('contact@earpiece-ai.com');
    // Show success message
    alert('Email Copied!');
  };

  return (
    <div className="about-container">
      <h1>Enhanced Augmented Reality (EAR) Piece AI</h1>
      <h2>Real-time Language Translation:</h2>
      <p>Instantly translate conversations in real-time, breaking down language barriers.</p>
      <h2>AR Aid:</h2>
      <p>Utilize augmented reality to provide visual aids and overlays during virtual meetings, enhancing understanding and engagement.</p>
      <h2>Collaboration Tools:</h2>
      <p>Integrated tools for sharing documents, whiteboarding, and collaborative editing.</p>
      <h2>HEAR from US:</h2>
      <p>Be the first to know when we launch! Sign up for our newsletter to receive updates and exclusive early access.</p>
      <form onSubmit={handleEmailSubmit}>
        <div className="email-input">
          <input
            type="email"
            placeholder="example@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" className="submit-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-check-circle"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
              <polyline points="22 4 12 14.01 9 11.01" />
            </svg>
          </button>
        </div>
      </form>
      <h2>Contact:</h2>
      <p className="contact-email" onClick={handleCopyEmail}>
        contact@earpiece-ai.com
      </p>
    </div>
  );
}

export default About;
