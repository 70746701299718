import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css'; // Ensure this file includes the new styles

const NavBar = () => {
  return (
    <nav className="navbar">
      <Link to="/" className="navbar-brand">
        <span className="ear-piece">Ear Piece  </span>
        <span className="ai">  AI</span>
      </Link>
    </nav>
  );
}

export default NavBar;
